import Constants from '../constants.js'

import AuthUtil from '../authUtil'
import axios from 'axios'

export default {
  // START TODO use these endpoints rather than the dups in apps
  // async newAppTaxon
  // async deleteAppTaxon
  // async updateTaxonCriteria
  // async updateServiceSetting,
  // async getTaxonSelectors
  // async invalidateTaxon
  // async getDeveloperAppCategories
  // END TODO use these endpoints rather than the dups in apps

  async getTaxonItems (appId, taxonId, groupId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/items`
    if (groupId) {
      getUrl = `${getUrl}/?group=${groupId}`
    }
    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getTaxonItem (appId, taxonId, itemId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}`
    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async exportTaxonItems (appId, taxonId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const exportUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/export`

    const payload = {
      criteria: {},
      filters: {}
    }

    return axios.post(exportUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      },
      responseType: 'stream'
    })
  },
  async migrateCatalogItems (appId) {

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/migrate`

    const response = await axios.post(postUrl, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async discoverCatalogItems (appId, searchURL, containerSelector, pathPrefix, ignoreTerm) {
    // Payload {url: string, containerSelector?: string. pathPrefix?: string, ingoreList?: Array<string>}

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/lookup`
    const payload = {
      searchURL: searchURL
    }

    if (containerSelector) {
      payload.containerSelector = containerSelector
    }
    if (pathPrefix) {
      payload.pathPrefix = pathPrefix
    }
    if (ignoreTerm) {
      payload.ignoreList = [ignoreTerm]
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getSavedCatalogLookups (appId) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/lookups`

    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async saveCatalogLookup (appId, searchURL, containerSelector, pathPrefix, ignoreTerm, previousResult) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/lookups`

    const payload = {
      searchURL: searchURL
    }

    if (containerSelector) {
      payload.containerSelector = containerSelector
    }
    if (pathPrefix) {
      payload.pathPrefix = pathPrefix
    }
    if (ignoreTerm) {
      payload.ignoreList = [ignoreTerm]
    }
    if (previousResult) {
      payload.previousResult = previousResult
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async deleteCatalogLookup (appId, lookupId) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/lookups/${lookupId}`

    const response = await axios.delete(deleteUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async importCatalog (appId, importData) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/import`
    const payload = {
      catalogJSON: importData
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async parseCatalog (appId, importData) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/parse`
    const payload = {
      catalogJSON: importData
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async parseShopifyCatalog (appId, taxonMappings, addUnmappedCategories,  siteProtocol) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const parseShopifyUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/parseshopify`

    const payload = {
      taxonMappings: taxonMappings,
      addUnmappedCategories: addUnmappedCategories
    }

    if (siteProtocol) {
      payload.siteProtocol = siteProtocol
    }

    const response = await axios.post(parseShopifyUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async readCatalogFeed (appId, feedURL) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/read`
    const payload = {
      feedURL: feedURL
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async uploadTaxonItems (appId, taxonId, itemsList ) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/items`
    const payload = {
      items: itemsList
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async addCatalogItems (appId, urls ) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items`
    const payload = {
      items: []
    }
    urls.forEach(itemUrl => {
      const itemData = {
        url: itemUrl
      }
      payload.items.push(itemData)
    });

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async addTaxonItems (appId, taxonId, urls, groupId ) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/items`
    const payload = {
      items: []
    }
    urls.forEach(itemUrl => {
      const itemData = {
        url: itemUrl
      }
      if (groupId) {
        itemData.groups = {}
        itemData.groups[groupId] = true
      }
      payload.items.push(itemData)
    });

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async setCatalogItemComparisonTaxon (appId, itemId, taxonId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}`
    const payload = {
      taxonId: taxonId
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async modifyTaxonItemGroups (appId, itemId, groups, nameSlug, syncURLs) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}`
    const payload = {
      groups: groups
    }
    if (nameSlug) {
      payload.nameSlug = nameSlug
    }
    if (syncURLs === true || syncURLs === false) {
      payload.syncURLs = syncURLs
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async modifyTaxonItemURLs (appId, itemId, urls) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}`
    const payload = {
      urls: urls
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async setTaxonItemUsedInComparisonTraining (appId, taxonId, url) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/training/taxons/${taxonId}/trained`
    // const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/urltrained`
    const payload = {
      url: url,
      appId: appId
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async deleteTaxonItems (appId, taxonId, itemId ) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/items/${itemId}`

    const response = await axios.delete(deleteUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getTaxonItemGroups (appId, taxonId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/groups`
    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async addTaxonItemGroup (appId, taxonId, groupData ) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/groups`

    let response = null
    if (groupData.name) {
      response = await axios.post(postUrl, groupData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
        }
      })
    }

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async updateTaxonItemGroup (appId, taxonId, groupId, changes ) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/groups/${groupId}`
    const payload = {
      name: changes.name
    }
    if (typeof changes.feedCategoryMapping !== 'undefined') { // Passing null will delete it
      payload.feedCategoryMapping = changes.feedCategoryMapping
    }
    // if (changes.crawlingEntryPoint) {
    if (typeof changes.crawlingEntryPoint !== 'undefined') { // Passing null will delete it
      payload.crawlingEntryPoint = changes.crawlingEntryPoint
    }
    if (changes.taxonGroupURLs) {
      payload.taxonGroupURLs = changes.taxonGroupURLs
    }
    if (changes.filterId) {
      payload.filterId = changes.filterId
    } else if (changes.filterId == false) {
      payload.filterId = false
    }

    if (changes.isSubFilter) {
      payload.isSubFilter = changes.isSubFilter
    } else if (changes.isSubFilter == false) {
      payload.isSubFilter = false
    }

    if (changes.rules) {
      payload.rules = changes.rules
    }
    if (changes.useAndOperator == true || changes.useAndOperator == false) {
      payload.useAndOperator = changes.useAndOperator
    }

    if (typeof changes.clientCollectionId !== 'undefined') { // Passing null will delete it
      payload.clientCollectionId = changes.clientCollectionId
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async deleteTaxonItemGroup (appId, taxonId, groupId ) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/groups/${groupId}`

    const response = await axios.delete(deleteUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getTaxonFilters (appId, taxonId, subFilterId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/filters`
    if (subFilterId) {
      getUrl = `${getUrl}/?subFilter=${subFilterId}`
    }
    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  
    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getAllTaxonFilters (appId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/filters`
    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  
    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async newTaxonFilter (appId, filterName, taxonId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const endpointUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/filters`
    const response = await axios.post(endpointUrl, {name: filterName, taxon: taxonId}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  
    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async updateTaxonFilter (appId, payload) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const filterId = payload.id
    const taxonStatus = payload.taxonStatus
    const name = payload.name
    const taxonClientAttributeMapping = payload.taxonClientAttributeMapping

    const endpointUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/filters/${filterId}`

    const updates = {}
    if (taxonStatus) {
      updates.taxonStatus = taxonStatus//{id: taxonStatus.id, enabled: taxonStatus.enabled}
    }
    if (name) {
      updates.name = name
    }
    if (taxonClientAttributeMapping) {
      updates.taxonClientAttributeMapping = taxonClientAttributeMapping
    }
    const response = await axios.post(endpointUrl, updates, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  
    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async deleteTaxonFilter (appId, taxonId, filterId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const endpointUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/filters/${filterId}`
    const response = await axios.delete(endpointUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  
    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async addGroupToTaxonFilter (appId, taxonId, groupId, filterId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/groups/${groupId}`
    const payload = {
      filterId: filterId
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async findUniqeValuesInTaxon (appId, taxonId, criteriaId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const valuesUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/criteria/${criteriaId}/values`

    const response = await axios.get(valuesUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return []
    }
  },
  async getTaxonAttributeValues (appId, taxonId, criteriaId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    // /developer/apps/:app/taxons/:taxon/attributes/:attribute/values
    let getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/attributevalues`
    if (criteriaId) {
      getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/attributes/${criteriaId}/values`
    }
    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  
    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async updateTaxonAttributeValues (appId, taxonId, criteriaId, values) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    // /developer/apps/:app/taxons/:taxon/attributes/:attribute/values
    let getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/attributes/${criteriaId}/values`

    const payload = {
      values: values
    }

    const response = await axios.post(getUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  
    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getComparisonSettings (appId) {
    // /developer/apps/:app/sdk/settings/comparison
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/sdk/settings/comparison`

    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getTaxonComparisonSettings (appId, taxonId) {
    // /developer/apps/:app/sdk/settings/comparison
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const getUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/sdk/settings/comparison`

    const response = await axios.get(getUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async deleteTaxonComparisonSettings (appId, taxonId) {
    // /developer/apps/:app/sdk/settings/comparison
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/sdk/settings/comparison`

    const response = await axios.delete(deleteUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async setTaxonComparisonSettings (appId, settings, taxonId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/sdk/settings/comparison`
    const payload = {
      settings: settings
    }
    if (taxonId) {
      payload.taxon = taxonId
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async setTaxonComparisonLayoutSettings (appId, settings) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/sdk/settings/comparison/layout`
    const payload = {
      settings: settings
    }

    const response = await axios.post(postUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  }
}
