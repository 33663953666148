<template>
  <div class="apps-wrapper page-container fill-height">
    <v-container fluid class="pb-6" :class="introClass">

      <v-container class="heading-container fill-height d-flex justify-center align-center">
        <div class="d-flex justify-center justify-md-start">
          <div class="title-wrapper">
            <div :class="titleClass">
              <!-- <span class="title-highlight">
                The easiest way to help your customers pick the best&mldr;
              </span> -->
              Help your customers <span class="title-highlight">{{serviceMessage}}</span>
            </div>
            <!-- <div class="white--text mt-4 text-h4">
              Join the AI revolution and turn your existing content into features that drive customer learning and improve sales
            </div> -->
            <div class="white--text mt-4 text-h4 title-highlight" :class="{'padded': $vuetify.breakpoint.smAndDown}">
              AI-Powered ecommerce solutions to Reduce Cart Abandonment, Accelerate Conversions, Boost Revenue
            </div>
          </div>
        </div>
      </v-container>

    </v-container>
    <div v-if="inStaging">
      <div class="info-section px-4 px-md-0">
        <v-container class="section-container d-flex justify-center pb-6">
          This is a Pikle staging environment.
        </v-container>
      </div>
    </div>
    <div v-show="!inStaging" class="services-section">
      <!-- <div>
        <div class="info-section no-overflow px-4 px-md-0">
          <v-container class="section-container pb-6">
            <div class="mb-6">
              <h2 class="mt-6 pt-6 text-center" :class="{'d-flex flex-column': $vuetify.breakpoint.smAndDown}">
                What Pikle ecommerce adds to your store
              </h2>
              <div class="d-flex service-cards-wrapper align-center justify-md-space-between my-6 pt-6" :class="{'flex-column': $vuetify.breakpoint.smAndDown}">
                
                <v-card class="py-3 d-flex flex-column justify-space-between align-center service-card mt-6 text-center comparison-card" :class="{'service-card-left-connector': $vuetify.breakpoint.mdAndUp}" :elevation="6">
                  <v-card-title class="justify-center">Product Comparison</v-card-title>
                  <v-card-text class="pt-3">
                    Add configurable product comparison to your store without any code
                  </v-card-text>
                  <div class="mt-2 learn-more-link-wrapper"><a class="learn-more-link" href="/comparison">Learn more</a></div>
                </v-card>
                  <v-card class="py-3 d-flex flex-column justify-space-between align-center service-card mt-6 text-center filtering-card" :class="{'service-card-right-connector': $vuetify.breakpoint.mdAndUp}" :elevation="6">
                  <v-card-title class="justify-center">Product Filtering</v-card-title>
                  <v-card-text class="pt-3">
                    Get more customers to the right place with feature filtering as a service
                  </v-card-text>
                  <div class="mt-2 learn-more-link-wrapper"><a class="learn-more-link" href="/filtering">Learn more</a></div>
                </v-card>
              </div>
              <div class="d-none d-md-flex justify-center clients-store-section">
                <v-card outlined class="pa-6 info--text">
                  https://yourstore.com
                </v-card>
              </div>
              <div class="d-none d-md-flex justify-center section-connector-wrapper">
                <div class="section-connector">
                  &nbsp;
                </div>
              </div>
            </div>
          </v-container>
        </div>
      </div>
      <div class="info-section section-dark px-6 px-md-0" :class="{'mob-and-down': $vuetify.breakpoint.smAndDown}">
        <v-container class="section-container white--text cta-container">
          <h1 class="mb-6 d-none d-md-block">Start with your existing content</h1>
          <div class="d-flex flex-column flex-md-row align-center mt-6 mb-4">
            <div class="text-h2 mr-4">01</div>
            <div class="text-h5"><b class="d-md-none">Gather your data.</b> Use Pikle AI to turn your existing plain content into useful data</div>
          </div>
          <div class="d-flex align-start justify-center mt-6 mb-4 flex-wrap">
            <v-img
              alt="Pikle Machine Learning"
              :src="require('@/assets/homepage/steps/Pikle-example-web-content.webp')"
              width="100%"
              max-width="410"
              class="rounded"
            ></v-img>
            <div>
              <v-icon color="white" x-large class="mx-6">{{$vuetify.breakpoint.smAndDown ? 'mdi-arrow-down' : 'mdi-arrow-right'}}</v-icon>
            </div>
            <v-img
              alt="Pikle Machine Learning"
              :src="require('@/assets/homepage/steps/Pikle-example-imported-content.webp')"
              width="100%"
              max-width="310"
              class="rounded"
            ></v-img>
          </div>
        </v-container>
      </div>
      <div class="info-section px-4 px-md-0">
        <v-container class="section-container">
          <div class="d-flex flex-column flex-md-row align-center mt-6 mb-4">
            <div class="text-h2 mr-4">02</div>
            <div class="text-h5"><b>Enable services</b>. Design how you want to filter and compare products to help your customers learn and choose. With simple integrations it has never been easier to help customers choose the right product from your catalog</div>
          </div>
          <div class="d-flex align-start mt-6 mb-4 flex-wrap">
            <div class="mr-md-6 mt-6">
              <div class="my-4 text-h4">Pluggable Filtering UI</div>
              <v-img
                v-if="!$vuetify.breakpoint.smAndDown"
                alt="Pikle Machine Learning"
                :src="require('@/assets/homepage/steps/Pikle-example-filter.webp')"
                width="100%"
                max-width="210"
              ></v-img>
            </div>
            <v-img
              v-if="$vuetify.breakpoint.smAndDown"
              alt="Pikle Machine Learning"
              :src="require('@/assets/homepage/steps/Pikle-example-filter.webp')"
              width="100%"
              max-width="210"
            ></v-img>
            <div class="ml-md-6 mt-6">
              <div class="my-4 text-h4">Pluggable Comparison UI</div>
              <v-img
                v-if="!$vuetify.breakpoint.smAndDown"
                alt="Pikle Machine Learning"
                :src="require('@/assets/homepage/steps/Pikle-example-compare-board.webp')"
                width="100%"
                max-width="510"
              ></v-img>
            </div>
            <v-img
              v-if="$vuetify.breakpoint.smAndDown"
              alt="Pikle Machine Learning"
              :src="require('@/assets/homepage/steps/Pikle-example-compare-board.webp')"
              width="100%"
              max-width="410"
            ></v-img>
          </div>
          <div class="text-h4 my-4">Or, build your own UI driven by Pikle APIs</div>
          <v-card color="black" class="snippet-card">
            <v-card-text>
              <pre class="snippet-code-pre">
https://connect.pikle.online/api/v1/filters

body: {
  appKey: "yourappkey",
  taxon: "laptops"
}
              </pre>
            </v-card-text>
          </v-card>
          <div class="text-h5 my-4">Response</div>
          <v-card color="black" class="snippet-card">
            <v-card-text>
              <pre class="snippet-code-pre">
{
  ...
  "4iJeK2mvGdZiqdSRFDrf": {
      "name": "Price",
      "orderInTaxon": 1,
      "groups": {
          "FtHS0B5iY1O8neJnocot": {
              "name": "$4000-5999",
              "itemCount": 53,
              "orderInFilter": 1,
          },
          "ZbmWGYxXDvwnKO3yw503": {
              "name": "$2000-$3999",
              "itemCount": 48,
              "orderInFilter": 0,
          }
      }
  },
  ...
}
              </pre>
            </v-card-text>
          </v-card>
        </v-container>
      </div> -->
      <div class="info-section px-4 px-md-0" style="border-top: 1px solid #DDD;">
        <v-container class="section-container">
          <div class="d-flex align-start justify-center mt-6 mb-4 flex-wrap">
            <v-img
              alt="Pikle Ecommerce Overview"
              :src="require('@/assets/homepage/EcommerceOverview.webp')"
              width="100%"
              max-width="700"
            ></v-img>
          </div>
        </v-container>
      </div>
      <div class="info-section section-try px-6 px-md-0" :class="{'mob-and-down': $vuetify.breakpoint.smAndDown}">
        <v-container class="section-container white--text cta-container">
          <h1 class="mb-4">Discover what's hidden in your content and empower your customers & business with Pikle!</h1>
          <!-- <div class="text-md-justify">
            <p>
              Pikle ecommerce is in development and available via a pilot program.
            </p>
            <p>
              You can register your interest in joining the pilot program to be part of our journey.
            </p>
          </div> -->
          <div class="d-flex justify-start">
            <v-btn @click="learnMore" class="primary-action-btn mt-4" x-large color="secondary">
              Learn More
            </v-btn>
          </div>
        </v-container>
      </div>
    </div>
    <div>
      <v-container class="d-flex justify-center align-center text-caption py-6 flex-column flex-md-row">
        <div class="ma-4">
          © 2025 ecommerce.pikle.online
        </div>
        <div class="ma-4">
          <v-btn x-small text to="/privacy" target="_blank">Privacy Statement</v-btn>
        </div>
        <div class="ma-4">
          <v-btn x-small text to="/terms" target="_blank">Terms of Service</v-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

import Constants from '../constants.js'

export default {
  name: 'PikleEcommerceHome',
  data () {
    return {
      tab: null,
      advantagesList: [
        {id: 'a', icon: 'service', description: 'Our service works independently of your content management system and reduces manual data entry tasks.' },
        {id: 'b', icon: 'test-tube', description: 'Build and test feature rich comparison without making any changes to your website or paying developers.' },
        {id: 'c', icon: 'code-tags', description: 'Once you are happy with your setup you can enable a comparison UI on your site as easily as you can add most social plugins.' }
      ],
      serviceMessageIndex: 0,
      serviceMessageInterval: null,
      serviceMessageList: [
        'filter',
        'compare',
        'learn',
        'choose'
      ]
    }
  },
  computed: {
    ...mapState({
      developerAccess: state => state.user.developerAccess,
      userPrefsLoaded: state => state.user.loaded
    }),
    // showFeatures () {
    //   return this.showAll || this.showComparison || this.showFiltering || this.showMonitoring
    // },
    serviceMessage () {
      return this.serviceMessageList[this.serviceMessageIndex]
    },
    inStaging () {
      return Constants.PIKLE_ENV && Constants.PIKLE_ENV === 'staging'
    },
    hasDeveloperAccess () {
      return !this.userPrefsLoaded || (this.userPrefsLoaded && this.developerAccess)
    },
    titleClass () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'mission-title-sm'
        case 'sm': return 'mission-title-sm'
        case 'md': return 'mission-title-sm'
        case 'lg': return 'mission-title'
        case 'xl': return 'mission-title'
        default: return 'mission-title'
      }
    },
    introClass () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'intro-section sm'
        case 'sm': return 'intro-section sm'
        case 'md': return 'intro-section sm'
        default: return 'intro-section'
      }
    },
    leftColumnClass () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return ''
        case 'sm': return ''
        default: return 'left-side-info'
      }
    },
    rightColumnClass () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return ''
        case 'sm': return ''
        default: return 'right-side-info'
      }
    },
    sdkSnippet () {
      return `&lt;div id=&quot;pikle-root&quot;&gt;&lt;/div&gt;
&lt;script&gt;
  (...);
&lt;/script&gt;`
    },
    buttonSnippet () {
      return `&lt;div class=&quot;pikle-compare-button&quot;
  data-href=&quot;...&quot;
&lt;/div&gt;`
    },
    statusSnippet () {
      return `&lt;div class=&quot;pikle-compare-status&quot;&gt;
&lt;/div&gt;`
    }
  },
  async mounted () {

    this.startServiceMessageProgression()

    // const showAllQuery = this.$route.query.showAll
    // const showComparisonQuery = this.$route.query.showComparison
    // const showFilteringQuery = this.$route.query.showFiltering

    // this.showAll = showAllQuery && showAllQuery === 'true'
    // this.showComparison = showComparisonQuery && showComparisonQuery === 'true'
    // this.showFiltering = showFilteringQuery && showFilteringQuery === 'true'
    
    // const self = this
    // window.addEventListener("scroll", function() {
    //   self.reveal(self.$refs.revealCard);
    // });
    
    // await this.$nextTick()
    // self.reveal(self.$refs.revealCard);

    // if (window.pikleVimeo && typeof window.pikleVimeo.init === "function") {
    //   window.pikleVimeo.init('vimeoPlayer')
    // }

    // this.player = new Player( this.$refs.vimeoPlayer , {
    //     id: 650229726,
    //     maxwidth: 600,
    //     responsive: true
    // })

  },
  methods: {
    // registerInterest: function () {
    //   // window.open(`/register/alpha`);
    //   window.location.href = `/register/alpha`
    // },
    learnMore: function () {
      window.open(`https://www.pikle.ai`);
      // window.location.href = `/register/alpha`
    },
    startServiceMessageProgression: function () {
      // repeat with the interval of 5 seconds
      this.serviceMessageIndex = 0
      this.serviceMessageInterval = setInterval(() => this.incrementServiceMessage(), 2000)
    },
    incrementServiceMessage: function () {
      if (this.serviceMessageIndex < this.serviceMessageList.length -1) {
        this.serviceMessageIndex++
      } else {
        this.serviceMessageIndex = 0
      }
      // else if (this.serviceMessageInterval) {
      //   clearInterval(this.serviceMessageInterval)
      // }
    },
    reveal: function(items) {
      var verticalEdge   = window.scrollY + window.innerHeight - 200;

      // console.log(`verticalEdge = ${verticalEdge}`)
      items.forEach((item) => {
        const top = item.$el.offsetTop
        // const left = item.$el.offsetLeft
        // console.log(`top = ${top}`)

        if (top > 0 && verticalEdge >= top) {
          // console.log(`revealing top = ${top} verticalEdge = ${verticalEdge}`)
          item.$el.classList.add('revealed')
        }
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

h1, h2, h3 {
  // font-family: 'Montserrat';
  font-family: 'Poppins', sans-serif;
}

@keyframes glide  {
   from {
      left:0px;
      top:0px;
   }
   
   to {
      left:0px;
      top:-20px;
   }

}

@keyframes grow {
    0% {
        transform: scale(1.0);
        margin-left: -230px;
        // margin-left: 0px;
        top: 235px;
        opacity: 1;
    }
    100% {
        transform: scale(1.7);
        margin-left: -330px;
        // margin-left: 200px;
        top: 280px;
        opacity: 0.1;
    }
}

@keyframes growMobile {
    0% {
        transform: scale(1.0);
        margin-left: 0px;
        top: 235px;
        opacity: 1;
    }
    100% {
        transform: scale(1.4);
        margin-left: 0px;
        // margin-left: 200px;
        top: 280px;
        opacity: 0.1;
    }
}

.page-container {
  font-size: 1.4rem;
  position: relative;
  background: #FFF;

  .heading-container {
    position: relative;
  }

  .logo-wrapper {
    // padding-left: 100px;

    &.mob-and-down {
      padding-left: 0px;
    }
  }

  .title-wrapper {
    max-width: 800px;
  }

  .logo-container {
    position: relative;
    margin-left: -8px;
    animation: glide 5s ease-in-out alternate infinite;
  }
  .shadow-container {
    position: absolute;
    top: 235px;
    margin-left: -230px;
    opacity: 1;
    animation: grow 5s ease-in-out alternate infinite;

    &.mob-and-down {
      margin-left: 0px;
      animation: growMobile 5s ease-in-out alternate infinite;
    }
  }

  .services-section {
    overflow: hidden;
  }

  .about-card {
    flex-grow: 1;
    height: 90%;

    h3 {
      padding: 30px 20px 20px 20px;
      font-weight: 500;
    }

    &.sm-and-down {
      h2 {
        font-size: 1.7rem;
      }
    }

    &.above {
      margin-top: -50px;
    }

    :after, :before {
      content: '';
      width: 100%;
      height: 0;
      border-style: solid;
      border-width: 90px 18vw 0 18vw;
      border-color: rgb(75, 14, 52) transparent transparent transparent;
      top: 100%;
      left: 0px;
      position: absolute;
    }

    &.lg-and-up {
      :after, :before {
        border-width: 90px 12vw 0 12vw;
      }
    }
  }

  .service-card {
    max-width: 250px;
    min-height: 230px;
    position: relative;
    border: 1px solid rgb(158, 0, 119);
    padding: 0 !important;

    .v-card__title {
      color: #FFF;
      background-color: #870080;
      font-size: 1.6rem;
      word-break: keep-all;
      margin: 0;
      width: 100%;
      display: block;
    }

    .v-card__text {
      font-size: 1.1rem;
      line-height: 1.5rem;
      padding: 10px;
      color: #222;
    }

    .learn-more-link-wrapper {
      width: 100%;
    }

    .learn-more-link {
      display: block;
      width: 100%;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #870080;
        color: #FFF;
      }
    }

    a {
      text-decoration: none;
    }

    &.comparison-card {
      .v-card__title {
        // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.3) 30%, rgba(154, 89, 191, 0.2) 65%, rgba(255, 255, 255, 0.1) 99%), url('~@/assets/homepage/comparisoncolors.webp');
        // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.3) 30%, rgba(53, 20, 73, 0.7) 55%, rgba(19, 7, 7, 0.8) 99%), url('~@/assets/homepage/comparisoncolors.webp');
        background-image: linear-gradient(45deg, rgba(0, 16, 160, 0.4) 30%, rgba(51, 4, 77, 0.9) 60%), url('~@/assets/homepage/comparisoncolors.webp');
      
        background-size: cover;
        background-position: bottom center;
      }
      &:hover .v-card__title {
        background-image: linear-gradient(190deg, rgba(0, 16, 160, 0.4) 30%, rgba(51, 4, 77, 0.9) 60%), url('~@/assets/homepage/comparisoncolors.webp');
      }
    }

    &.filtering-card {
      .v-card__title {
        // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.3) 30%, rgba(53, 20, 73, 0.7) 55%, rgba(19, 7, 7, 0.8) 99%), url('~@/assets/homepage/filtercubes.webp');
        background-image: linear-gradient(190deg, rgba(0, 16, 160, 0.4) 30%, rgba(51, 4, 77, 0.9) 60%), url('~@/assets/homepage/filtercubes.webp');
      
        background-size: cover;
        background-position: bottom center;
      }
      &:hover .v-card__title {
        background-image: linear-gradient(45deg, rgba(0, 16, 160, 0.4) 30%, rgba(51, 4, 77, 0.9) 60%), url('~@/assets/homepage/filtercubes.webp');
      }
    }

    &.insights-card {
      .v-card__title {
        // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.3) 30%, rgba(154, 89, 191, 0.2) 65%, rgba(255, 255, 255, 0.1) 99%), url('~@/assets/homepage/digitalcubes.webp');
        // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.3) 30%, rgba(53, 20, 73, 0.7) 55%, rgba(19, 7, 7, 0.8) 99%), url('~@/assets/homepage/digitalcubes.webp');
        background-image: linear-gradient(190deg, rgba(0, 16, 160, 0.2) 30%, rgba(51, 4, 77, 0.8) 60%), url('~@/assets/homepage/digitalcubes.webp');
      
        background-size: cover;
        background-position: bottom center;
      }
      &:hover .v-card__title {
        background-image: linear-gradient(45deg, rgba(0, 16, 160, 0.2) 30%, rgba(51, 4, 77, 0.8) 60%), url('~@/assets/homepage/digitalcubes.webp');
      }
    }
  }

  .service-cards-wrapper {
    position: relative;
    z-index: 10;
  }

  .service-card-left-connector:before {
    /* we want to draw a line, so no content needed */
    content: '';

    transform: rotate(45deg);

    /* this sets the position and size of the pseudo-element */
    position: absolute;
    bottom: -50px;
    width: 250px;
    left: 125px;
    height: 2px;
    background-color: rgb(234, 0, 255);
    background-image: linear-gradient(45deg, rgb(158, 0, 119) 20%, rgb(255, 91, 222) 80%);

    /* draw behind child elements */
    z-index: -1;
  }

  .service-card-middle-connector:before {
    /* we want to draw a line, so no content needed */
    content: '';

    transform: rotate(90deg);

    /* this sets the position and size of the pseudo-element */
    position: absolute;
    bottom: 0px;
    left: -26px;
    width: 300px;
    height: 2px;
    background-color: rgb(234, 0, 255);
    background-image: linear-gradient(90deg, rgb(158, 0, 119) 40%, rgb(255, 91, 222) 80%);

    /* draw behind child elements */
    z-index: -1;
  }

  .service-card-right-connector:before {
    /* we want to draw a line, so no content needed */
    content: '';

    transform: rotateX(180deg) rotate(45deg);

    /* this sets the position and size of the pseudo-element */
    position: absolute;
    bottom: -50px;
    right: 125px;
    width: 250px;
    height: 2px;
    background-color: rgb(234, 0, 255);
    background-image: linear-gradient(-45deg, rgb(158, 0, 119) 20%, rgb(255, 91, 222) 80%);

    /* draw behind child elements */
    z-index: -1;
  }

  .section-connector-wrapper {
    position: relative;
    height: 1px;

    .section-connector {
      position: relative;
      display: block;
      width: 2px;
    }
  }

  .section-connector:before {
    /* we want to draw a line, so no content needed */
    content: '';

    transform: rotate(90deg);

    /* this sets the position and size of the pseudo-element */
    position: absolute;
    top: 40px;
    left: -100px;
    width: 200px;
    height: 2px;
    background-color: rgb(234, 0, 255);
    background-image: linear-gradient(-90deg, rgb(158, 0, 119) 30%, rgb(255, 91, 222) 80%);

    /* draw behind child elements */
    z-index: 1;
  }

  .cta-container h1 {
    font-size: clamp(1rem, 4.7vw, 4rem);
  }

  .card-4:before {
    /* we want to draw a line, so no content needed */
    content: '';

    transform: rotate(90deg);

    /* this sets the position and size of the pseudo-element */
    position: absolute;
    top: 0;
    left: -20px;
    width: 300px;
    height: 2px;
    background-color: rgb(234, 0, 255);
    background-image: linear-gradient(-90deg, rgb(158, 0, 119) 30%, rgb(255, 91, 222) 80%);

    /* draw behind child elements */
    z-index: -1;
  }

  .clients-store-section {
    position: relative;
    margin-top: 80px;
    z-index: 20;
    
    .v-card {
      border: 2px solid rgb(255, 91, 222);
    }
  }

  .hero-card:after, .hero-card:before {
    content: '';
    width: 100%;
    height: 0;
    border-style: solid;
    border-width: 40px 15px 0 15px;
    border-color: rgb(91, 84, 88) transparent transparent transparent;
    top: 100%;
    left: 0px;
    position: absolute;
  }

  .info-card {
    // background: #201563;
    
    flex-grow: 1;
    height: 90%;

    .info-card-text {
      padding: 40px 20px 20px 20px !important;

      h2 {
        font-size: 2rem;
        line-height: 3rem;
      }

      p {
        margin-top: 10px;
        padding: 20px 0px;
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }

    &.above {
      padding: 5px 20px 80px 20px !important;
      margin-top: -130px;
    }

    &.medium-and-up {
      max-width: 80%;
      text-align: justify;
    }

  }

  .point-card {
    flex: 1 1 0px;
    font-size: 1.3rem;
  }

  .snippet-card {

    .snippet-code-pre {
      color: #FFF;
      white-space: pre-wrap;
      font-family: monospace;
      text-align: left;
      min-width: 300px;
    }
  }

  .section-container {
      max-width: 900px;

      h2.sm-and-down {
        font-size: 2rem;
      }
  }

  .intro-section {
    height: 100vh;
    max-height: 750px !important;
    min-height: 500px;
    background-color: #222;
    
    
    // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.5) 30%, rgba(154, 89, 191, 0.4) 65%, rgba(255, 255, 255, 0.1) 99%), url('~@/assets/homepage/choosecolors.webp');
    // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.3) 30%, rgba(154, 89, 191, 0.2) 65%, rgba(255, 255, 255, 0.1) 99%), url('~@/assets/homepage/choosecolors.webp');

    // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.5) 30%, rgba(154, 89, 191, 0.4) 65%, rgba(255, 255, 255, 0.1) 99%), url('~@/assets/homepage/jboxes.webp');
    // background-image: linear-gradient(180deg, rgba(74, 0, 80, 0.8) 5%, rgba(235, 0, 252, 0.3) 30%, rgba(154, 89, 191, 0.2) 65%, rgba(255, 255, 255, 0.1) 99%), url('~@/assets/homepage/jboxes.webp');
    background-image: linear-gradient(-20deg, rgba(0, 16, 160, 0.8) 30%, rgba(166, 0, 255, 0.8) 60%), url('~@/assets/homepage/jboxes.webp');
    
    background-size: cover;
    background-position: center center;

    // ALT
    // // background-image: linear-gradient(180deg, rgba(218, 24, 209, 0.3) 60%, rgba(11, 1, 17, 0.6) 75%, rgb(0, 0, 0) 95%), url('~@/assets/homepage/futureblocks.webp');
    // // background-image: linear-gradient(180deg, rgba(11, 1, 17, 0.4) 20%, rgba(218, 24, 209, 0.3) 30%, rgba(218, 24, 209, 0.4) 60%, rgba(11, 1, 17, 0.6) 75%, rgb(0, 0, 0) 95%), url('~@/assets/homepage/futureblocks.webp');
    // background-image: linear-gradient(175deg, rgba(110, 0, 120, 0.7) 30%, rgba(0, 16, 160, 0.3) 60%, rgba(11, 1, 17, 0.6) 75%, rgb(0, 0, 0) 95%), url('~@/assets/homepage/futureblocks.webp');

    // background-size: 100% auto;
    // background-position: top center;

    &.sm {
      background-size: cover;
      background-position: center center;
    }
  }

  .info-section {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    min-height: 400px;

    h1 {
      font-size: 3.1rem;
      font-weight: 700;
    }

    h2 {
      font-size: 2.8rem;
      font-weight: 700;
    }

    &.minimal-text {
      min-height: 300px;
    }

    &.no-y-pad {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.no-overflow {
      overflow: hidden;
    }

    &.section-dark {
      // background: #1c0c81 url('~@/assets/homepage/logo-bkg-repeat.png') repeat top left !important;
      // background-image: linear-gradient(-20deg, rgba(0, 16, 160, 0.3) 30%, rgba(166, 0, 255, 0.8) 60%), url('~@/assets/homepage/galaxy.webp');
      background-image: linear-gradient(-20deg, rgba(0, 16, 160, 0.3) 30%, rgba(75, 0, 116, 0.9) 50%), url('~@/assets/homepage/futureblocks.webp');
      background-size: cover;
      background-position: bottom center;
    }

    &.section-dark-alt {
      // background: #1c0c81 url('~@/assets/homepage/logo-bkg-repeat.png') repeat top left !important;
      // background-image: linear-gradient(-20deg, rgba(0, 16, 160, 0.3) 30%, rgba(166, 0, 255, 0.8) 60%), url('~@/assets/homepage/galaxy.webp');
      background-image: linear-gradient(-40deg, rgba(0, 16, 160, 1) 30%, rgba(166, 0, 255, 0.8) 50%), url('~@/assets/homepage/comparisoncolors.webp');
      background-size: cover;
      background-position: bottom center;

      &.right {
        background-image: linear-gradient(-40deg, rgba(166, 0, 255, 0.3) 30%, rgba(0, 16, 160, 0.9) 60%), url('~@/assets/homepage/comparisoncolors.webp');
      }
    }
    
    &.section-dark-alt2 {
      // background: #1c0c81 url('~@/assets/homepage/logo-bkg-repeat.png') repeat top left !important;
      // background-image: linear-gradient(-20deg, rgba(0, 16, 160, 0.3) 30%, rgba(166, 0, 255, 0.8) 60%), url('~@/assets/homepage/galaxy.webp');
      background-image: linear-gradient(190deg, rgba(0, 16, 160, 0.4) 30%, rgba(51, 4, 77, 0.9) 60%), url('~@/assets/homepage/digitalcubes.webp');
      background-size: cover;
      background-position: bottom center;
    }
    
    &.section-try {
      // background: #1c0c81 url('~@/assets/homepage/logo-bkg-repeat.png') repeat top left !important;
      background-image: linear-gradient(-10deg, rgba(0, 16, 160, 0.1) 30%, rgba(77, 0, 119, 0.8) 60%), url('~@/assets/homepage/galaxy.webp');
      // background-image: linear-gradient(-10deg, rgba(0, 16, 160, 0.1) 30%, rgba(77, 0, 119, 0.8) 60%), url('~@/assets/homepage/digitalcubes.webp');
      background-size: cover;
      background-position: top center;
      min-height: 600px;
      padding-top: 200px;

      &.mob-and-down {
        padding-top: 60px;
      }
    }

    &.section-black {
      background: #222;
    }
    &.section-blue {
      background: #0010a0;
    }

    &.arrows-bkg {
      // background-color: #f5f5f5;
      border-top: 1px solid #DDD;
      // background-image: linear-gradient(#e5e5e5e5, #f5f5f5);
      // background: #f5f5f5 url('~@/assets/homepage/separator.png') repeat top left !important;
    }

    .left-side-info {
      max-width: 700px;
    }
    .right-side-info {
      max-width: 400px;
    }

    .advantage-card {
      display: flex;
      align-items: flex-start;
      margin: 40px 0px;
      background: inherit;
      opacity: 0;
      transform: translateX(100px);
      transition: all .5s ease 0.25s;

      &.revealed {
        opacity: 1;
        transform: translateX(0);
      }

      .advantage-icon {
        min-width: 80px;
        text-align: center;
        margin-top: 8px;
      }
    }
  }

  .service-info-card-title {
    line-height: 1.8rem;
  }

  .mission-title {
    font-size: 4.3rem;
    font-weight: 700;
    color: #FFF;
    // padding: 10px;
    // margin-top: 220px;
    max-width: 800px;
    // font-family: 'Montserrat';
    font-family: 'Poppins', sans-serif;
    display: inline;
  }

  .title-highlight {
    // color: #6290fc;
    padding: 0px 8px;
    background-color: #28002fb6;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    &.padded {
      padding: 10px;
    }
  }

  .mission-title-sm {
    font-size: 3.6rem;
    font-weight: 700;
    color: #FFF;
    padding: 10px;
    // margin-top: 100px;
    text-align: left;
    font-family: 'Poppins', sans-serif;

    .title-highlight {
      // display: block;
      // color: #6290fc;
      padding: 0px 8px;
      background-color: #3b00468a;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      // text-transform: uppercase;
    }
  }
}
</style>
