import Vue from 'vue'
import appsAPI from '../../api/apps'
import taxonsAPI from '../../api/taxons'
import userAPI from '../../api/user'
import * as types from '../types/apps'
import * as commonTypes from '../types/common'

import { onSnapshot } from "firebase/firestore";

const calculateTaxonScorePenalty = (idealCount, actualCount, penaltyPerDiff, penalizeLessThan) => {
  let res = 0
  const diff = penalizeLessThan ? idealCount - actualCount : actualCount - idealCount;
  if (diff > 0) {
    res = diff * penaltyPerDiff
    // const penalty = diff * penaltyPerDiff
    // res = currentScore * penalty
  }
  return res
}

export const getDefaultState = () => ({
  appsList: [],
  invitationsList: [],
  fetchingStatus: false,
  fetchingAppStatus: false,
  fetchingFiltersStatus: false,
  fetchingGroupsStatus: false,
  newDialogOpen: false,
  newCategoryDialogOpen: false,
  // serviceModeDialogOpen: false,
  addTaxonItemsDialogOpen: false,
  newTaxonItemGroupDialogOpen: false,
  editTaxonItemDialogOpen: false,
  currentApp: null,
  appUnSubscribeCallback: null,
  appTaxonsUnSubscribeCallback: null,
  currentAppNavExpansions: {},
  currentTaxon: null,
  currentTaxonScore: null,
  currentTaxonScoreMessages: null,
  currentTaxonGroup: null,
  currentTaxonGroups: null,
  currentTaxonFilters: null,
  currentTaxonItems: null,
  currentTaxonSubFilter: null,
  currentCompareServiceTab: 0,
  currentAppDeleted: false,
  currentAppFeedSize: 0,
  currentAppMissingInFeedCount: 0,
  deletingCurrentApp: false,
  deletingTaxon: false,
  refreshingTaxon: false,
  lastTaxonRefreshCount: null,
  memberProfiles: [],
  membersUnSubscribeCallbacks: [],
  paginatedCatalogItemsCount: 0,
  serviceModes: {
    comparison: {
      enabled: {
        id: 'enabled',
        title: "Live",
        description: 'Everyone can compare',
        priority: 0
      },
      testing: {
        id: 'testing',
        title: "Testing",
        description: 'Only you see comparison',
        priority: 1
      },
      disabled: {
        id: 'disabled',
        title: "Disabled",
        description: 'Comparison is disabled',
        priority: 2
      }
    },
    filtering: {
      enabled: {
        id: 'enabled',
        title: "Live",
        description: 'Everyone can filter',
        priority: 0
      },
      testing: {
        id: 'testing',
        title: "Testing",
        description: 'Only you see filters',
        priority: 1
      },
      disabled: {
        id: 'disabled',
        title: "Disabled",
        description: 'Filtering is disabled',
        priority: 2
      }
    },
    search: {
      enabled: {
        id: 'enabled',
        title: "Enabled",
        description: 'Search is enabled',
        priority: 0
      },
      disabled: {
        id: 'disabled',
        title: "Disabled",
        description: 'Search is disabled',
        priority: 2
      }
    },
    leads: {
      enabled: {
        id: 'enabled',
        title: "Enabled",
        description: 'We will send leads directly to you',
        priority: 0
      },
      disabled: {
        id: 'disabled',
        title: "Disabled",
        description: 'Enable to start generating leads',
        priority: 2
      }
    },
    insights: {
      enabled: {
        id: 'enabled',
        title: "Enabled",
        description: 'Market insights are enabled',
        priority: 0
      },
      disabled: {
        id: 'disabled',
        title: "Disabled",
        description: 'Enable to gather market insights',
        priority: 2
      }
    }
  }
})

// initial state
const state = getDefaultState()

// getters
const getters = {
}

// actions
const actions = {
  [commonTypes.ACTION_RESET_STATE]: ({ commit }) => {
    // First unsubscribe from any data listeners
    commit(types.MUTATION_SET_APP_UNSUBSCRIBE_CALLBACK, null)
    commit(types.MUTATION_SET_APP_TAXONS_UNSUBSCRIBE_CALLBACK, null)
    commit(types.MUTATION_CLEAR_MEMBERS_UNSUBSCRIBE_CALLBACKS)
    commit(commonTypes.MUTATION_RESET_STATE)
  },
  [types.ACTION_RESET_TAXON_STATE]: ({ commit }) => {
    commit(types.MUTATION_SET_CURRENT_TAXON_SCORE, null)
    commit(types.MUTATION_SET_CURRENT_TAXON_SCORE_MESSAGES, null)
    commit(types.MUTATION_SET_TAXON_GROUPS, {groups: null})
    commit(types.MUTATION_SET_TAXON_FILTERS, {filters: null})
    commit(types.MUTATION_SET_CURRENT_TAXON_GROUP, null)
    commit(types.MUTATION_SET_TAXON_ITEMS, null)
  },
  async [types.FETCH_DEVELOPER_APPS] ({ commit }) {
    commit(types.MUTATION_FETCHING_STATUS, true)
    const apps = await appsAPI.getDeveloperApps()
    if (apps) {
      commit(types.MUTATION_SET_APPS, apps)
    }
    commit(types.MUTATION_FETCHING_STATUS, false)
  },
  async [types.FETCH_APPS_INVITATIONS] ({ commit }) {
    // commit(types.MUTATION_FETCHING_STATUS, true)
    const invitiations = await appsAPI.getAppInvitations()
    if (invitiations) {
      commit(types.MUTATION_SET_INVITATIONS, invitiations)
    }
    // commit(types.MUTATION_FETCHING_STATUS, false)
  },
  async [types.FETCH_DEVELOPER_APP] ({ state, commit, dispatch }, payload) {
    // Unload any existing app data. AKA reset
    if (!state.currentApp || state.currentApp.id !== payload.appId || payload.forceUpdate) {
      if (state.currentApp && state.currentApp.id !== payload.appId) {
        // Clear state any time we change app. Or are
        dispatch(commonTypes.ACTION_RESET_STATE)
      }
      commit(types.MUTATION_FETCHING_APP_STATUS, true)
      const appData = await appsAPI.getDeveloperApp(payload.appId)
      if (appData) {
        commit(types.MUTATION_FETCHING_APP_STATUS, false)
        commit(types.MUTATION_SET_CURRENT_APP, appData)
        commit(types.MUTATION_SET_CURRENT_APP_TAXON, null)
        // Clear taxon data
        dispatch(types.ACTION_RESET_TAXON_STATE)

        // Subscribe to snapshot listener to keep things up to date with the backend.
        const appRef = appsAPI.appRef(payload.appId)
        const appUnsubscribe = onSnapshot(appRef, (snapshot) => {
          if (snapshot && snapshot.data() && state.currentApp) {
            const modifiedData = snapshot.data()
            // Update latest catalogItemsCount
            if (state.currentApp.catalogItemsCount !== modifiedData.catalogItemsCount) {
              commit(types.MUTATION_UPDATE_CATALOG_ITEMS_COUNT, modifiedData.catalogItemsCount)
            }
          }
        })

        commit(types.MUTATION_SET_APP_UNSUBSCRIBE_CALLBACK, appUnsubscribe)

        // Subscribe to taxons snapshot listender to keep things up to date with the backend
        const taxonsCollectionRef = appsAPI.appTaxonsRef(payload.appId)
        const appTaxonsUnsubscribe = onSnapshot(taxonsCollectionRef, (snapshot) => {
          snapshot.docChanges().forEach((change) => {

            // export const MUTATION_ADD_CURRENT_APP_TAXON        = 'addTaxonToCurrentAppMutation'
            // export const MUTATION_DELETE_CURRENT_APP_TAXON     = 'deleteTaxonFromCurrentAppMutation'
            // export const MUTATION_SET_TAXON_COUNTS     = 'updateTaxonCountsFromCurrentAppMutation'

            // Handle added
            // if (change.type === "added") {
            //   const docData = change.doc.data()
            //   const docId = change.doc.id
            // }
            // Modified
            if (change.type === "modified") {
              const docData = change.doc.data()
              const docId = change.doc.id

              commit(types.MUTATION_SET_TAXON_COUNTS, {taxonId: docId, itemsCount: docData.itemsCount || 0, groupsCount: docData.groupsCount || 0, taxonURLs: docData.taxonURLs || []})

            }
            // Removed
            // else if (change.type === "removed") {
            //   const docData = change.doc.data()
            //   const docId = change.doc.id
            // }
          });
        });

        commit(types.MUTATION_SET_APP_TAXONS_UNSUBSCRIBE_CALLBACK, appTaxonsUnsubscribe)

      }
    }
  },
  async [types.REFRESH_DEVELOPER_APP] ({ commit }, payload) {
      const appData = await appsAPI.getDeveloperApp(payload.appId)
      if (appData) {
        commit(types.MUTATION_SET_CURRENT_APP, appData)
      }
  },
  async [types.FETCH_CURRENT_TAXON_GROUPS] ({ state, commit }) {
    // Unload any existing app data. AKA reset
    if (state.currentApp && state.currentTaxon && state.fetchingGroupsStatus == false) {
      commit(types.MUTATION_FETCHING_GROUPS_STATUS, true)
      const groupsData = await taxonsAPI.getTaxonItemGroups(state.currentApp.id, state.currentTaxon.id)
      // console.log(`ABOUT TO SET GROUP DATAT = ${JSON.stringify(groupsData)}`)
      commit(types.MUTATION_SET_TAXON_GROUPS, {groups: groupsData})
      commit(types.MUTATION_FETCHING_GROUPS_STATUS, false)

      return groupsData
    }
  },
  async [types.FETCH_CURRENT_TAXON_FILTERS] ({ state, commit }) {
    if (state.currentApp && state.currentTaxon && state.fetchingFiltersStatus == false) {
      commit(types.MUTATION_FETCHING_FILTERS_STATUS, true)
      const pageFilterId = state.currentTaxonSubFilter ? state.currentTaxonGroups[state.currentTaxonSubFilter].filterId : null
      const filtersData = await taxonsAPI.getTaxonFilters(state.currentApp.id, state.currentTaxon.id, pageFilterId)
      commit(types.MUTATION_FETCHING_FILTERS_STATUS, false)
      commit(types.MUTATION_SET_TAXON_FILTERS, {filters: filtersData})

      return filtersData
    }
  },
  async [types.FETCH_ALL_TAXON_FILTERS] ({ state }) {
    if (state.currentApp) {
      const filtersData = await taxonsAPI.getAllTaxonFilters(state.currentApp.id)
      return filtersData
    }
  },
  async [types.ADD_NEW_TAXON_FILTER] ({ state, commit }, filterName) {
    if (state.currentApp && state.currentTaxon) {
      const response = await taxonsAPI.newTaxonFilter(state.currentApp.id, filterName, state.currentTaxon.id)
      if (response && response.newEntryId) {
        const taxons = {}
        taxons[state.currentTaxon.id] = true
        commit(types.MUTATION_ADD_TAXON_FILTER, {id: response.newEntryId, data: {name: filterName, taxons: taxons}})
      }
    }
  },
  async [types.ADD_EXISTING_TAXON_FILTER] ({ state, commit }, payload) {
    if (state.currentApp && state.currentTaxon) {
      await taxonsAPI.updateTaxonFilter(state.currentApp.id, {id: payload.filter.id, taxonStatus: payload.taxonStatus, name: payload.name})
      const taxons = {}
      taxons[state.currentTaxon.id] = true
      commit(types.MUTATION_ADD_TAXON_FILTER, {id: payload.filter.id, data: {name: payload.filter.name, taxons: taxons}})
    }
  },
  async [types.SET_TAXON_FILTER_VISIBILITY] ({ state, commit }, payload) {
    if (state.currentApp && state.currentTaxon) {
      await taxonsAPI.updateTaxonFilter(state.currentApp.id, {id: payload.filter.id, taxonStatus: payload.taxonStatus})
      commit(types.MUTATION_UPDATE_TAXON_FILTER_VISIBILITY, {id: payload.filter.id, taxonId: state.currentTaxon.id, isVisible: payload.taxonStatus.visible})
    }
  },
  async [types.SET_TAXON_FILTER_CLIENT_ATTRIBUTE_ID] ({ state, commit }, payload) {
    if (state.currentApp && state.currentTaxon && payload.taxonClientAttributeMapping) {
      await taxonsAPI.updateTaxonFilter(state.currentApp.id, {id: payload.filter.id, taxonClientAttributeMapping: payload.taxonClientAttributeMapping})
      commit(types.MUTATION_UPDATE_TAXON_FILTER_CLIENT_ATTRIBUTE_ID, {id: payload.filter.id, taxonId: state.currentTaxon.id, value: payload.taxonClientAttributeMapping.value})
    }
  },
  async [types.FETCH_CURRENT_TAXON_ITEMS] ({ state, commit }, payload) {
    if (state.currentApp && state.currentTaxon ) {
      const groupId = state.currentTaxonGroup || null;
      let itemsData = []

      let itemsResonse = null
      if (payload.taxonFilters) {
        itemsResonse = await appsAPI.getFilteredCatalogItems(state.currentApp.id, state.currentTaxon.id, payload.region, payload.itemsPerPage, payload.lastItem, payload.sortBy, payload.sortDesc, payload.taxonFilters)
      } else {
        const filters = { groupId: groupId, taxonId: state.currentTaxon.id, ...payload.filters}
        itemsResonse = await appsAPI.getPaginatedCatalogItems(state.currentApp.id, payload.itemsPerPage, payload.lastItem, null, payload.sortBy, payload.sortDesc, filters)
      }

      itemsData = itemsResonse && itemsResonse.items || []
      commit(types.MUTATION_UPDATE_TAXON_ITEMS_COUNT, itemsResonse.totalItemsCount)
      
      commit(types.MUTATION_SET_TAXON_ITEMS, itemsData)

      return itemsData
    }
  },
  [types.FETCH_CURRENT_APP_TAXON_SELECTORS] ({ commit }, payload) {
    appsAPI.getTaxonSelectors(payload.appId, payload.taxonId, payload.criteria).then((selectorsData) => {
      commit(types.MUTATION_SET_TAXON_SELECTORS, {taxonId: payload.taxonId, selectors: selectorsData})
    })
  },
  // This is long running function with multiple awaits. The state can change between awaits.
  // this means for example there might not be a currentTaxon anymore after taxon selectors have been fetch
  async [types.ACTION_CALCULATE_CURRENT_TAXON_SCORE] ({ state, commit }, payload) {
    
    if (!state.currentApp || !state.currentTaxon || !payload.criteria ) {
      return false
    }

    let existingSelectors = payload.existingSelectors || null
    if (!existingSelectors) {
      existingSelectors = await appsAPI.getTaxonSelectors(state.currentApp.id, state.currentTaxon.id, payload.criteria)
    }

    // CLear messages
    commit(types.MUTATION_SET_CURRENT_TAXON_SCORE_MESSAGES, null)

    const scoreMessages = {}
    let trainingScore = 0
    const maxTrainingScore = 5;
    // const maxGroupTrainingScore = state.currentTaxonGroups ? 2 : 0;
    // let maxCriteriaScore = maxTrainingScore - maxGroupTrainingScore
    let maxCriteriaScore = maxTrainingScore

    // Ideal counts to score against
    const perCriteriaMinSuccessCount = 5
    const perCriteriaSelectorTypesCount = 2 // At most two selector strategoes should be in the list (e.g. a DOM text strategy plus an ML one.)
    const perCriteriaSelectorCount = 2

    // Penalties for missing counts
    const penaltyPerMissingSuccessCount = 0.2
    const penaltyPerExtraSelectorType = 0.3
    const penaltyPerExtraSelector = 0.05

    // Penalties for groups with no training
    // const perGroupMinTrainingCount = 2
    // const penaltyPerUntrainedGroup = 0.2

    // For each criteria item
    let maxScorePerCriteria = payload.criteria.length ? (maxCriteriaScore / payload.criteria.length) : 0;
    payload.criteria.forEach((criteriaItem) => {
      const criteriaKey = criteriaItem.id
      const selectorTypes = []
      const pagePositions = []
      const domSelectors = []
      let criteriaSuccessCount = 0

      // console.log(`Scoring ${criteriaItem.name}`)
      const criteriaSelectorList = existingSelectors[criteriaKey].domainSelectors
      const criteriaSelectorCount = criteriaSelectorList.length

      let criteriaScore = criteriaSelectorCount === 0 ? criteriaSelectorCount : maxScorePerCriteria // Score will be zero if there are no selectors otherwise will start max and be penalized based on selector data
      // calculateTaxonScorePenalty(currentScore, idealCount, actualCount, penaltyPerDiff, penalizeLessThan)
      const selectorCountPenalty = calculateTaxonScorePenalty(perCriteriaSelectorCount, criteriaSelectorCount, penaltyPerExtraSelector, false)
      // console.log(`selector count penalty being applied. penalty = ${selectorCountPenalty}`)
      criteriaScore = criteriaScore - (criteriaScore * selectorCountPenalty)
      // console.log(`criteriaScore = ${criteriaScore}`)


      criteriaSelectorList.forEach((nextSelector) => {
        if (selectorTypes.indexOf(nextSelector.selectorId) === -1) {
          selectorTypes.push(nextSelector.selectorId)
        }
        if (nextSelector.domSelector) {
          domSelectors.push(nextSelector.domSelector)
        }
        if (nextSelector.pagePosition) {
          pagePositions.push(nextSelector.pagePosition)
        }
        criteriaSuccessCount += nextSelector.successCount
      })

      // Apply selector types penalty
      // console.log(`selectorTypes = ${JSON.stringify(selectorTypes)}`)
      const selectorTypesPenalty = calculateTaxonScorePenalty(perCriteriaSelectorTypesCount, selectorTypes.length, penaltyPerExtraSelectorType, false)
      // console.log(`selector types penalty being applied. penalty = ${selectorTypesPenalty}`)
      criteriaScore = criteriaScore - (criteriaScore * selectorTypesPenalty)

      // Apply successCount penalty
      // Add total selector count penalty
      // console.log(`criteriaSuccessCount for ${criteriaItem.name} = ${criteriaSuccessCount}`)
      const successCountPenalty = calculateTaxonScorePenalty(perCriteriaMinSuccessCount, criteriaSuccessCount, penaltyPerMissingSuccessCount, true)
      // console.log(`successCountPenalty = ${successCountPenalty}`)
      if (successCountPenalty > 0) {
        if (!scoreMessages['low-success-count']) {
          scoreMessages['low-success-count'] = {
            title: 'Some features have a low success count',
            description: `It is best practice to keep training until all features have a high success count.`,
            messages: []
          }
        }
        scoreMessages['low-success-count'].messages.push(`${criteriaItem.name} has a low success count.`)
      }
      criteriaScore = criteriaScore - (successCountPenalty)

      // console.log(`Next criteriaScore = ${criteriaScore}`)
      trainingScore += Math.max(0, criteriaScore)
    })

    // Recheck state after long awaits
    if (!state.currentApp || !state.currentTaxon) {
      return false
    }

    // Penalize training score when groups exist with no trained items in them.
    // const groupsData = await taxonsAPI.getTaxonItemGroups(state.currentApp.id, state.currentTaxon.id)
    // if (groupsData) {
    //   // const allTaxonItems = await taxonsAPI.getTaxonItems(state.currentApp.id, state.currentTaxon.id, null)
    //   const taxonGroupKeys = Object.keys(groupsData)

    //   const groupsTrainingMap = {}
    //   taxonGroupKeys.forEach((groupId) => {
    //     groupsTrainingMap[groupId] = {itemCount: groupsData[groupId].itemCount || 0, trainingCount: groupsData[groupId].trainingCount || 0, groupName: groupsData[groupId].name}
    //   })

    //   // TODO. Too expensive. Better to store a training count in the DB and increment that in functions
    //   Object.keys(allTaxonItems).forEach((itemId) => {
    //     const item = allTaxonItems[itemId]
    //     if (item.groups) {
    //       Object.keys(item.groups).forEach((groupId) => {
    //         groupsTrainingMap[groupId].itemCount = groupsTrainingMap[groupId].itemCount + 1
    //         if (item.usedInComparisonTraining) {
    //           groupsTrainingMap[groupId].trainingCount = groupsTrainingMap[groupId].trainingCount + 1
    //         }
    //       })
    //     }
    //   })

    //   let groupsWithItems = 0
    //   Object.keys(groupsTrainingMap).forEach((itemId) => {
    //     const groupsItemCount = groupsTrainingMap[itemId].itemCount
    //     if (groupsItemCount > 0) {
    //       groupsWithItems++
    //     }
    //   })

    //   // console.log(`groupsTrainingMap = ${JSON.stringify(groupsTrainingMap)}`)
    //   const maxScorePerGroup = groupsWithItems > 0 ? (maxGroupTrainingScore / groupsWithItems) : 0;

    //   Object.keys(groupsTrainingMap).forEach((itemId) => {
    //     const groupsItemCount = groupsTrainingMap[itemId].itemCount
    //     if (groupsItemCount > 0) { // Only penalized groups which actually have items
    //       let groupTrainingScore = taxonGroupKeys.length === 0 ? 0 : maxScorePerGroup
    //       const groupsTrainingCount = groupsTrainingMap[itemId].trainingCount
    //       const untrainedGroupsPenalty = calculateTaxonScorePenalty(perGroupMinTrainingCount, groupsTrainingCount, penaltyPerUntrainedGroup, true)

    //       if (untrainedGroupsPenalty > 0) {
    //         const groupName = groupsTrainingMap[itemId].groupName
    //         if (!scoreMessages['untrained-groups']) {
    //           scoreMessages['untrained-groups'] = {
    //             title: 'Some groups are not well represented in training',
    //             description: `It is best practice to train using a few items from each of your groups. Groups often represent different products with different data.`,
    //             messages: []
    //           }
    //         }
    //         scoreMessages['untrained-groups'].messages.push(`${groupName} only contains ${groupsTrainingCount} trained item(s).`)
    //       }
    //       groupTrainingScore = groupTrainingScore - (untrainedGroupsPenalty)

    //       // console.log(`Next groupTrainingScore = ${groupTrainingScore}`)
    //       trainingScore += Math.max(0, groupTrainingScore)
    //     }

    //   })

    // }

    // this.taxonScore = trainingScore
    commit(types.MUTATION_SET_CURRENT_TAXON_SCORE, trainingScore)
    const updatedMessages = Object.keys(scoreMessages).length ? scoreMessages : null;
    commit(types.MUTATION_SET_CURRENT_TAXON_SCORE_MESSAGES, updatedMessages)

    return true;
  },
  [types.CREATE_NEW_DEVELOPER_APP] ({ commit }, payload) {
    const tempId = '_' + Math.random().toString(36).substr(2, 9) // Random temporary ID. Will be used to remove the placeholder
    const placeholderAppData = {id: tempId, isCreating: true, ...payload}
    commit(types.MUTATION_ADD_APP, placeholderAppData)
    appsAPI.newDeveloperApp(payload, (response) => {
      commit(types.MUTATION_REMOVE_APP, tempId)
      if (response && response.appData) {
        commit(types.MUTATION_ADD_APP, response.appData)
      }
      if (response && response.defaultTaxon) {
        const defaultTaxonData = {...response.defaultTaxon}
        delete defaultTaxonData.id
        commit(types.MUTATION_ADD_CURRENT_APP_TAXON, {taxonId: response.defaultTaxon.id, taxonData: defaultTaxonData})
      }
    })
  },
  [types.CREATE_NEW_APP_TAXON] ({ state, commit, dispatch }, payload) {
    if (state.currentApp) {
      appsAPI.newAppTaxon(state.currentApp.id, payload, (res) => {
        if (res && res.status === 'success') {
          const taxonData = {...payload}
          delete taxonData.id
          commit(types.MUTATION_ADD_CURRENT_APP_TAXON, {taxonId: res.taxonId, taxonData: taxonData})

          commit(types.MUTATION_SET_CURRENT_APP_TAXON, {id: res.taxonId, taxonData: taxonData})

          // Clear taxon data
          dispatch(types.ACTION_RESET_TAXON_STATE)
        }
      })
    }
  },
  async [types.UPDATE_CURRENT_GROUP] ({ state, commit }, payload) {
    if (state.currentTaxonGroup) {

      const res = await taxonsAPI.updateTaxonItemGroup(state.currentApp.id, payload.taxonId, payload.groupId, payload)
      if (res && res.status === 'success') {
        // commit(types.MUTATION_MODIFY_CURRENT_GROUP, payload)
        commit(types.MUTATION_MODIFY_TAXON_GROUP, {groupId: state.currentTaxonGroup, ...payload})
      }
      return res
    }
  },
  async [types.UPDATE_TAXON_GROUP] ({ state, commit }, payload) {
    if (state.currentTaxonGroups && state.currentTaxonGroups[payload.groupId]) {
      const res = await taxonsAPI.updateTaxonItemGroup(state.currentApp.id, payload.taxonId, payload.groupId, payload)
      if (res && res.status === 'success') {
        commit(types.MUTATION_MODIFY_TAXON_GROUP, payload)
      }
      return res
    }
  },
  async [types.ACTION_UPDATE_APP_NAME] ({ state, commit }, newName) {
    if (state.currentApp) {

      const res = await appsAPI.updateDeveloperAppName(state.currentApp.id, newName)
      if (res && res.status === 'success') {
        commit(types.MUTATION_SET_APP_NAME, newName)
      }
      return res
    }
  },
  async [types.ACTION_UPDATE_APP_STORE_DETAILS] ({ state, commit }, payload) {
    if (state.currentApp) {

      const res = await appsAPI.updateDeveloperAppStoreDetails(state.currentApp.id, payload)
      if (res && res.status === 'success') {
        commit(types.MUTATION_SET_APP_STORE_DETAILS, payload)
      }
      return res
    }
  },
  async [types.UPDATE_TAXON_CRITERIA] ({ state, commit }, payload) {
    if (state.currentApp) {
      const newCriteria = {}
      payload.criteria.forEach(criteriaItem => {
        // Not cloning the entire object becuase there are temporary properties like newItem which are just for this session in the UI
        newCriteria[criteriaItem.id] = {
          // newItem: false,
          name: criteriaItem.name,
          taxon: criteriaItem.taxon,
          userGenerated: criteriaItem.userGenerated || false,
          order: criteriaItem.order,
          ignoreInScrape: criteriaItem.ignoreInScrape,
          keywordOnly: criteriaItem.keywordOnly,

        }
        if (criteriaItem.stableValue === true || criteriaItem.stableValue === false) {
          newCriteria[criteriaItem.id].stableValue = criteriaItem.stableValue
        }
        // Update max value length overrides
        if (criteriaItem.maxValueLength) {
          newCriteria[criteriaItem.id].maxValueLength = criteriaItem.maxValueLength
        }
        // Update display name
        if (criteriaItem.displayName) {
          newCriteria[criteriaItem.id].displayName = criteriaItem.displayName
        }
        // Update clientAttributeId
        if (criteriaItem.clientAttributeId) {
          newCriteria[criteriaItem.id].clientAttributeId = criteriaItem.clientAttributeId
        }
      })

      const res = await appsAPI.updateTaxonData(state.currentApp.id, payload.taxonId, {criteria: newCriteria})
      if (res && res.status === 'success') {
        commit(types.MUTATION_SET_TAXON_CRITERIA, {taxonId: payload.taxonId, criteria: newCriteria})
      }
    }
  },
  async [types.UPDATE_TAXON_URLS] ({ state, commit }, payload) {
    if (state.currentApp && payload.taxonURLs) {
      const res = await appsAPI.updateTaxonData(state.currentApp.id, payload.taxonId, {taxonURLs: payload.taxonURLs})
      if (res && res.status === 'success') {
        commit(types.MUTATION_SET_TAXON_URLS, {taxonId: payload.taxonId, taxonURLs: payload.taxonURLs})
      }
    }
  },
  async [types.UPDATE_TAXON_ML_SETTINGS] ({ state, commit }, payload) {
    if (state.currentApp && state.currentTaxon && state.currentTaxon.id === payload.taxonId && payload.modelSettings) {
      const originalModelSettings = {}
      originalModelSettings.useAppCompletionModel = state.currentTaxon.useAppCompletionModel || false
      // originalModelSettings.useAppCompletionModelAsFallback = 
      commit(types.MUTATION_SET_TAXON_ML_SETTINGS, {taxonId: payload.taxonId, modelSettings: payload.modelSettings})
      const res = await appsAPI.updateTaxonData(state.currentApp.id, payload.taxonId, {modelSettings: payload.modelSettings})
      if (res && res.status !== 'success') {
        commit(types.MUTATION_SET_TAXON_ML_SETTINGS, {taxonId: payload.taxonId, modelSettings: originalModelSettings})
      }
    }
  },
  [types.UPDATE_APP_COMPARISON_AUTO_REFRESH_STATE] ({ state, commit }, payload) {
    if (state.currentApp && state.currentApp.services) {
      const existingSettings = state.currentApp.services.comparison || {}
      const newSettings = {taxonAutoRefresh: {}}
      newSettings.taxonAutoRefresh[payload.taxonId] = payload.autoRefresh
      const mergedSettings = {...existingSettings, ...newSettings}

      const taxonContenxt = payload.taxonId || null
      appsAPI.updateComparisonSettings(state.currentApp.id, taxonContenxt, 'taxonAutoRefresh', payload.autoRefresh).then((res) => {
        if (res && res.status === 'success') {
          commit(types.MUTATION_SET_SERVICE_SETTINGS, {id: payload.serviceId, settings: mergedSettings})
        }
      })
    }
  },
  [types.UPDATE_APP_COMPARISON_AUTO_IMPORT_STATE] ({ state, commit }, payload) {
    if (state.currentApp && state.currentApp.services) {
      const existingSettings = state.currentApp.services.comparison || {}
      const newSettings = {taxonAutoImport: {}}
      newSettings.taxonAutoImport[payload.taxonId] = payload.autoImport
      const mergedSettings = {...existingSettings, ...newSettings}

      const taxonContenxt = payload.taxonId || null
      appsAPI.updateComparisonSettings(state.currentApp.id, taxonContenxt, 'taxonAutoImport', payload.autoImport).then((res) => {
        if (res && res.status === 'success') {
          commit(types.MUTATION_SET_SERVICE_SETTINGS, {id: payload.serviceId, settings: mergedSettings})
        }
      })
    }
  },
  [types.UPDATE_APP_COMPARISON_AUTO_APPROVE_STATE] ({ state, commit }, payload) {
    if (state.currentApp && state.currentApp.services) {
      const existingSettings = state.currentApp.services.comparison || {}
      const newSettings = {taxonAutoApprove: {}}
      newSettings.taxonAutoApprove[payload.taxonId] = payload.autoApprove
      const mergedSettings = {...existingSettings, ...newSettings}

      const taxonContenxt = payload.taxonId || null
      appsAPI.updateComparisonSettings(state.currentApp.id, taxonContenxt, 'taxonAutoApprove', payload.autoApprove).then((res) => {
        if (res && res.status === 'success') {
          commit(types.MUTATION_SET_SERVICE_SETTINGS, {id: payload.serviceId, settings: mergedSettings})
        }
      })
    }
  },
  [types.UPDATE_APP_SEARCH_TAXON_AUTO_IMPORT_STATE] ({ state, commit }, payload) {
    if (state.currentApp && state.currentApp.services) {

      const existingSettings = state.currentApp.services.search || {}
      const newSettings = {taxonAutoImport: {}}
      newSettings.taxonAutoImport[payload.taxonId] = payload.autoImport
      const mergedSettings = {...existingSettings, ...newSettings}

      const taxonContenxt = payload.taxonId || null
      appsAPI.updateTaxonSearchSettings(state.currentApp.id, taxonContenxt, 'taxonAutoImport', payload.autoImport).then((res) => {
        if (res && res.status === 'success') {
          commit(types.MUTATION_SET_SERVICE_SETTINGS, {id: 'search', settings: mergedSettings})
        }
      })
    }
  },
  [types.UPDATE_APP_SEARCH_IMPORT_ALL_STATE] ({ state, commit }, payload) {
    if (state.currentApp && state.currentApp.services) {
    
      const existingSettings = state.currentApp.services.search || {}
      const newSettings = {indexEverything: payload.indexEverything}
      const mergedSettings = {...existingSettings, ...newSettings}

      appsAPI.updateSearchIndexAllSetting(state.currentApp.id, payload.indexEverything).then((res) => {
        if (res && res.status === 'success') {
          commit(types.MUTATION_SET_SERVICE_SETTINGS, {id: 'search', settings: mergedSettings})
        }
      })
    }
  },
  [types.UPDATE_APP_SERVICE_MODE] ({ state, commit }, payload) {
    if (state.currentApp && state.currentApp.services) {
      const existingSettings = state.currentApp.services[payload.serviceId] || {}
      const newSettings = {...existingSettings, ...payload.settings}
      const taxonContenxt = payload.taxonId || null
      appsAPI.updateServiceMode(state.currentApp.id, payload.serviceId, taxonContenxt, newSettings).then((res) => {
        if (res && res.status === 'success') {
          commit(types.MUTATION_SET_SERVICE_SETTINGS, {id: payload.serviceId, settings: newSettings})
        }
      })
    }
  },
  [types.DELETE_CURRENT_APP] ({ state, commit }) {
    if (state.currentApp) {
      commit(types.MUTATION_SET_CURRENT_APP_DELETING, true)
      appsAPI.deleteDeveloperApp(state.currentApp.id, (res) => {
        if (res && res.status === 'success') {
          commit(types.MUTATION_SET_CURRENT_APP_DELETED, true)
          commit(types.MUTATION_SET_CURRENT_APP_DELETING, false)
        }
      })
    }
  },
  async [types.DELETE_CURRENT_APP_TAXON] ({ state, commit, dispatch }, taxonId) {
    if (state.currentApp) {
      // console.log(`Will delete ${taxonId}`)
      commit(types.MUTATION_SET_DELETING_TAXON, true)
      const res = await appsAPI.deleteAppTaxon(state.currentApp.id,taxonId)
      if (res && res.status === 'success') {
        commit(types.MUTATION_DELETE_CURRENT_APP_TAXON, taxonId)
        commit(types.MUTATION_SET_CURRENT_APP_TAXON, null)
        // Clear taxon data
        dispatch(types.ACTION_RESET_TAXON_STATE)
      }
      commit(types.MUTATION_SET_DELETING_TAXON, false)
    }
  },
  async [types.DELETE_CURRENT_APP_TAXON_ITEM] ({ state, commit }, itemId) {
    let res = false
    if (state.currentApp && state.currentTaxon && state.currentTaxonItems) {
      
      commit(types.MUTATION_SET_TAXON_ITEM_DATA, {
        id: itemId, 
        deleting: true
      })
      // Item will be deleted
      // console.log(`Will delete item itself`)
      const deleteResponse = await taxonsAPI.deleteTaxonItems(state.currentApp.id, state.currentTaxon.id, itemId)

      if (deleteResponse) {
        commit(types.MUTATION_REMOVE_TAXON_ITEM, itemId)
        res = true
      }
      
    }
    return res
  },
  async [types.DELETE_CURRENT_APP_TAXON_ITEMS] ({ state, commit }, items) {
    
    let res = null
    if (state.currentApp && state.currentTaxon && state.currentTaxonItems) {
      
      items.forEach(item => {
        commit(types.MUTATION_SET_TAXON_ITEM_DATA, {
          id: item.id, 
          deleting: true
        })
      });
      // Items will be deleted from the taxon
      const deleteResponse = await appsAPI.bulkDeleteCatalogItems(state.currentApp.id, items, true)

      items.forEach(item => {
        commit(types.MUTATION_SET_TAXON_ITEM_DATA, {
          id: item.id, 
          deleting: false
        })
      });

      if (deleteResponse && deleteResponse.deletedItemIds) {
        res = deleteResponse.deletedItemIds
        deleteResponse.deletedItemIds.forEach(nextItemId => {
          commit(types.MUTATION_REMOVE_TAXON_ITEM, nextItemId)
        })
        
      }
      
    }
    return res
  },
  [types.ACTION_INVALIDATE_TAXON] ({ commit }, payload) {
    commit(types.MUTATION_SET_REFRESHING_TAXON, true)
    appsAPI.invalidateTaxon(payload.appId, payload.taxonId, (response) => {
      if (response && response.status === 'success') {
        commit(types.MUTATION_SET_REFRESHING_TAXON, false)
        commit(types.MUTATION_SET_LAST_TAXON_REFRESH_COUNT, response.refreshCount)
      }
    })
  },
  [types.ACTION_SET_APP_DOMAIN_VERIFIED] ({ commit }, payload) {
    appsAPI.setAppDomainVerified(payload.appId, payload.appKey, (response) => {
      if (response && response.status === 'success') {
        commit(types.MUTATION_SET_APP_DOMAIN_VERIFIED_STATUS, true)
      }
    })
  },
  [types.ACTION_SET_CURRENT_APP_TAXON] ({ commit, dispatch }, taxon) {
    commit(types.MUTATION_SET_CURRENT_APP_TAXON, taxon)
    // Clear taxon data
    dispatch(types.ACTION_RESET_TAXON_STATE)
  },
  [types.ACTION_SET_CURRENT_APP_TAXON_GROUP] ({ commit }, group) {
    commit(types.MUTATION_SET_CURRENT_TAXON_GROUP, group)
    // console.log(`Setting current taxon group`)
    // commit(types.MUTATION_SET_TAXON_ITEMS, null)
  },

  [types.ACTION_LOAD_APP_MEMBERS]: async ({ commit }, payload) => {
    commit(types.MUTATION_CLEAR_MEMBERS_UNSUBSCRIBE_CALLBACKS)
    commit(types.MUTATION_CLEAR_MEMBERS, null)
    
    // Chunk the member ids so we can query in chunks of 10
    const alMembers = [payload.ownerId, ...payload.memberIds]
    let i, j
    const chunk = 10
    const memberIdChunks = []
    for (i = 0, j = alMembers.length; i < j; i += chunk) {
      memberIdChunks.push(alMembers.slice(i, i + chunk))
    }
    for (let index = 0; index < memberIdChunks.length; index++) {

      const nextMemberIDsList = memberIdChunks[index]
      const unsubscribe = onSnapshot(userAPI.publicUsersDataRef(nextMemberIDsList), (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const userData = {id: change.doc.id, ... change.doc.data()}
            if (userData.id === payload.ownerId) {
              userData.isOwner = true
            }
            commit(types.MUTATION_ADD_MEMBER, userData)
          }
        })
      })

      commit(types.MUTATION_ADD_MEMBERS_UNSUBSCRIBE_CALLBACK, unsubscribe)
    }
  },
  [types.ACTION_SET_SYNC_CATALOG_META_DATA]: async ({ state, commit }, payload) => {
    if (state.currentApp) {
      commit(types.MUTATION_SET_SYNC_CATALOG_META_DATA, payload)
      await appsAPI.setSyncCatalogProductMetaData(state.currentApp.id, payload.syncEnabled, payload.onlySyncNewItems)
    }
  }
}

// mutations
const mutations = {
  [commonTypes.MUTATION_RESET_STATE] (state) {
    // console.log('RESETING STATE in Decisions Module')
    Object.assign(state, getDefaultState())
  },
  [types.MUTATION_NEW_APP_DIALOG] (state, dialogOpen) {
    state.newDialogOpen = dialogOpen
  },
  [types.MUTATION_NEW_COMPARE_CATEGORY_DIALOG] (state, dialogOpen) {
    state.newCategoryDialogOpen = dialogOpen
  },
  // [types.MUTATION_SERVICE_MODE_DIALOG] (state, dialogOpen) {
  //   state.serviceModeDialogOpen = dialogOpen
  // },
  [types.MUTATION_NEW_TAXON_ITEMS_DIALOG] (state, dialogOpen) {
    state.addTaxonItemsDialogOpen = dialogOpen
  },
  [types.MUTATION_EDIT_TAXON_ITEM_DIALOG] (state, dialogOpen) {
    state.editTaxonItemDialogOpen = dialogOpen
  },
  [types.MUTATION_NEW_ITEM_GROUP_DIALOG] (state, dialogOpen) {
    state.newTaxonItemGroupDialogOpen = dialogOpen
  },
  [types.MUTATION_ADD_APP] (state, app) {
    state.appsList.push(app)
  },
  [types.MUTATION_REMOVE_APP] (state, appId) {
    const appIndex = state.appsList.findIndex(next => next.id === appId)
    if (appIndex > -1) {
      state.appsList.splice(appIndex, 1)
    }
  },
  [types.MUTATION_SET_APPS] (state, apps) {
    state.appsList = apps
  },
  [types.MUTATION_SET_INVITATIONS] (state, invitationsList) {
    state.invitationsList = invitationsList
  },
  [types.MUTATION_FETCHING_STATUS] (state, status) {
    state.fetchingStatus = status
  },
  [types.MUTATION_FETCHING_APP_STATUS] (state, status) {
    state.fetchingAppStatus = status
  },
  [types.MUTATION_FETCHING_FILTERS_STATUS] (state, status) {
    state.fetchingFiltersStatus = status
  },
  [types.MUTATION_FETCHING_GROUPS_STATUS] (state, status) {
    state.fetchingGroupsStatus = status
  },
  [types.MUTATION_SET_CURRENT_APP] (state, appData) {
    state.currentApp = appData
  },
  [types.MUTATION_SET_CURRENT_APP_TAXON] (state, taxonData) {
    state.currentTaxon = taxonData
  },
  [types.MUTATION_SET_CURRENT_APP_TAXON_SUB_FILTER] (state, filterId) {
    state.currentTaxonSubFilter = filterId
  },
  [types.MUTATION_SET_CURRENT_APP_DELETING] (state, isDeleting) {
    state.deletingCurrentApp = isDeleting
  },
  [types.MUTATION_SET_DELETING_TAXON] (state, isDeleting) {
    state.deletingTaxon = isDeleting
  },
  [types.MUTATION_SET_CURRENT_APP_DELETED] (state, isDeleted) {
    state.currentAppDeleted = isDeleted
  },
  [types.MUTATION_SET_CURRENT_TAXON_SCORE] (state, taxonScore) {
    state.currentTaxonScore = taxonScore
  },
  [types.MUTATION_SET_CURRENT_TAXON_SCORE_MESSAGES] (state, messages) {
    state.currentTaxonScoreMessages = messages
  },
  [types.MUTATION_SET_CURRENT_TAXON_GROUP] (state, groupId) {
    state.currentTaxonGroup = groupId
  },
  [types.MUTATION_SET_CURRENT_APP_FEED_SIZE] (state, feedSize) {
    state.currentAppFeedSize = feedSize
  },
  [types.MUTATION_SET_CURRENT_APP_MISSING_IN_FEED_COUNT] (state, count) {
    state.currentAppMissingInFeedCount = count
  },
  // [types.MUTATION_MODIFY_CURRENT_GROUP] (state, payload) {
  //   if (state.currentTaxonGroups && state.currentTaxonGroup) {
  //     const groupItem = state.currentTaxonGroups[state.currentTaxonGroup]
  //     if (payload.name) {
  //       Vue.set(groupItem, 'name', payload.name)
  //     }
  //     if (payload.rules) {
  //       Vue.set(groupItem, 'rules', payload.rules)
  //     }
  //     if (payload.crawlingEntryPoint) {
  //       Vue.set(groupItem, 'crawlingEntryPoint', payload.crawlingEntryPoint)
  //     }
  //     if (payload.taxonGroupURLs) {
  //       Vue.set(groupItem, 'taxonGroupURLs', payload.taxonGroupURLs)
  //     }
  //     if (payload.filterId) {
  //       Vue.set(groupItem, 'filterId', payload.filterId)
  //     } else if (payload.filterId == false && groupItem.filterId) {
  //       Vue.set(groupItem, 'filterId', null)
  //     }
  //     if (payload.clientCollectionId) {
  //       Vue.set(groupItem, 'clientCollectionId', payload.clientCollectionId)
  //     }
  //   }
  // },
  [types.MUTATION_MODIFY_TAXON_GROUP] (state, payload) {
    if (state.currentTaxonGroups && state.currentTaxonGroups[payload.groupId]) {
      const groupItem = state.currentTaxonGroups[payload.groupId]
      if (payload.name) {
        Vue.set(state.currentTaxonGroups[payload.groupId], 'name', payload.name)
      }
      if (payload.crawlingEntryPoint) {
        Vue.set(state.currentTaxonGroups[payload.groupId], 'crawlingEntryPoint', payload.crawlingEntryPoint)
      }
      if (payload.taxonGroupURLs) {
        Vue.set(state.currentTaxonGroups[payload.groupId], 'taxonGroupURLs', payload.taxonGroupURLs)
      }
      if (payload.rules) {
        Vue.set(state.currentTaxonGroups[payload.groupId], 'rules', payload.rules)
      }
      if (payload.filterId) {
        Vue.set(state.currentTaxonGroups[payload.groupId], 'filterId', payload.filterId)
      } else if (payload.filterId == false && state.currentTaxonGroups[payload.groupId].filterId) {
        Vue.set(state.currentTaxonGroups[payload.groupId], 'filterId', null)
      }
      if (payload.isSubFilter) {
        Vue.set(groupItem, 'isSubFilter', payload.isSubFilter)
      } else if (payload.isSubFilter == false && groupItem.isSubFilter) {
        Vue.delete(groupItem, 'isSubFilter')
      }
      if (payload.clientCollectionId) {
        Vue.set(state.currentTaxonGroups[payload.groupId], 'clientCollectionId', payload.clientCollectionId)
      }
    }
  },
  [types.MUTATION_SET_CURRENT_COMPARE_SERVICE_TAB] (state, newTabId) {
    state.currentCompareServiceTab = newTabId
  },
  [types.MUTATION_SET_APP_DOMAIN_VERIFIED_STATUS] (state, isVerified) {
    if (state.currentApp) {
      Vue.set(state.currentApp, 'verifiedDomain', isVerified)
    }
  },
  [types.MUTATION_ADD_CURRENT_APP_TAXON] (state, payload) {
    if (state.currentApp) {
      if (!state.currentApp.taxons) {
        state.currentApp.taxons = {}
      }
      Vue.set(state.currentApp.taxons, payload.taxonId, payload.taxonData)
    }
  },
  [types.MUTATION_DELETE_CURRENT_APP_TAXON] (state, taxonId) {
    if (state.currentApp && state.currentApp.taxons) {
      Vue.delete(state.currentApp.taxons, taxonId)
    }
  },
  [types.MUTATION_SET_TAXON_COUNTS] (state, payload) {
    if (state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId]) {
      if (typeof payload.itemsCount !== 'undefined')
        Vue.set(state.currentApp.taxons[payload.taxonId], 'itemsCount', payload.itemsCount)
      if (typeof payload.groupsCount !== 'undefined')
        Vue.set(state.currentApp.taxons[payload.taxonId], 'groupsCount', payload.groupsCount)
    }
  },
  [types.MUTATION_SET_TAXON_CRITERIA] (state, payload) {
    if (state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId]) {
      Vue.set(state.currentApp.taxons[payload.taxonId], 'criteria', payload.criteria)
      // Update current taxon if needed
      if (state.currentTaxon && state.currentTaxon.id === payload.taxonId) {
        Vue.set(state.currentTaxon, 'criteria', payload.criteria)
      }
    }
  },
  [types.MUTATION_SET_TAXON_URLS] (state, payload) {
    if (state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId]) {
      Vue.set(state.currentApp.taxons[payload.taxonId], 'taxonURLs', payload.taxonURLs)
      // Update current taxon if needed
      if (state.currentTaxon && state.currentTaxon.id === payload.taxonId) {
        Vue.set(state.currentTaxon, 'taxonURLs', payload.taxonURLs)
      }
    }
  },
  [types.MUTATION_SET_TAXON_ML_SETTINGS] (state, payload) {
    if (payload.modelSettings && state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId] && state.currentTaxon && state.currentTaxon.id === payload.taxonId) {
      if (payload.modelSettings.useAppCompletionModel == true || payload.modelSettings.useAppCompletionModel == false) {
        Vue.set(state.currentTaxon, 'useAppCompletionModel', payload.modelSettings.useAppCompletionModel)  
      }
      if (payload.modelSettings.useAppCompletionModelAsFallback == true || payload.modelSettings.useAppCompletionModelAsFallback == false) {
        Vue.set(state.currentTaxon, 'useAppCompletionModelAsFallback', payload.modelSettings.useAppCompletionModelAsFallback)  
      }
    }
  },
  [types.MUTATION_SET_SYNC_CATALOG_META_DATA] (state, payload) {
    if (state.currentApp) {
      Vue.set(state.currentApp, 'syncCatalogProductMetaData', payload.syncEnabled)
      Vue.set(state.currentApp, 'onlySyncNewItems', payload.onlySyncNewItems)
    }
  },
  [types.MUTATION_SET_TAXON_GROUPS] (state, payload) {
    state.currentTaxonGroups = payload.groups
    // if (state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId]) {
    //   Vue.set(state.currentApp.taxons[payload.taxonId], 'groups', payload.groups)
    //
    //   // Update current taxon if needed
    //   if (state.currentTaxon && state.currentTaxon.id === payload.taxonId) {
    //     console.log(`Set group for current taxon ${JSON.stringify(payload.groups)}`)
    //     Vue.set(state.currentTaxon, 'groups', payload.groups)
    //   }
    // }
  },
  [types.MUTATION_SET_TAXON_FILTERS] (state, payload) {
    state.currentTaxonFilters = payload.filters
  },
  // [types.MUTATION_ADD_TAXON_FILTER] (state, payload) {
  //   if (state.currentTaxonFilters) {
  //     Vue.set(state.currentTaxonFilters, payload.id, payload.data)
  //   }
  // },
  [types.MUTATION_ADD_TAXON_FILTER] (state, payload) {
    if (state.currentTaxonFilters) {
      Vue.set(state.currentTaxonFilters, payload.id, payload.data)
    }
  },
  [types.MUTATION_UPDATE_TAXON_FILTER_VISIBILITY] (state, payload) {
    if (state.currentTaxonFilters && state.currentTaxonFilters[payload.id]) {
      if (!state.currentTaxonFilters[payload.id].visibilityInTaxon) {
        Vue.set(state.currentTaxonFilters[payload.id], 'visibilityInTaxon', {})
      }
      Vue.set(state.currentTaxonFilters[payload.id].visibilityInTaxon, payload.taxonId, payload.isVisible)
    }
  },
  [types.MUTATION_UPDATE_TAXON_FILTER_CLIENT_ATTRIBUTE_ID] (state, payload) {
    if (state.currentTaxonFilters && state.currentTaxonFilters[payload.id]) {
      if (!state.currentTaxonFilters[payload.id].clientAttributeIdInTaxon) {
        Vue.set(state.currentTaxonFilters[payload.id], 'clientAttributeIdInTaxon', {})
      }
      Vue.set(state.currentTaxonFilters[payload.id].clientAttributeIdInTaxon, payload.taxonId, payload.value)
    }
  },
  [types.MUTATION_SET_TAXON_ITEMS] (state, payload) {
    state.currentTaxonItems = payload
  },
  [types.MUTATION_SET_TAXON_ITEM_GROUPS] (state, changes) {
    if (state.currentTaxonItems) {
      const item = state.currentTaxonItems[changes.id]

      if (item) {
        Vue.set(item, 'groups', changes.groups)
        if (changes.urls) {
          Vue.set(item, 'urls', changes.urls)
        }
        if (changes.nameSlug) {
          Vue.set(item, 'nameSlug', changes.nameSlug)
        }
        if (changes.syncURLs === true || changes.syncURLs === false) {
          Vue.set(item, 'syncURLs', changes.syncURLs)
        }
      }
    }
  },
  [types.MUTATION_SET_TAXON_ITEM_GROUP_NAMES] (state, payload) {
    if (state.currentTaxonItems) {
      const item = state.currentTaxonItems[payload.id]
      Vue.set(item, 'groupNames', payload.groupNames)
    }
  },
  [types.MUTATION_SET_TAXON_ITEM_DATA] (state, itemData) {
    if (state.currentTaxonItems) {
      const itemModel = state.currentTaxonItems[itemData.id]
      
      if (itemModel) {
        if (itemData.scrapeState || itemData.scrapeState === null) {
          Vue.set(itemModel, 'scrapeState', itemData.scrapeState)
        }
        if (itemData.optionId || itemData.optionId === null) {
          Vue.set(itemModel, 'optionId', itemData.optionId)
        }
        if (itemData.optionContentState) {
          Vue.set(itemModel, 'optionContentState', itemData.optionContentState)
        }

        if (itemData.imgSrc) {
          Vue.set(itemModel, 'imgSrc', itemData.imgSrc)
        }

        if (itemData.approvedForComparison === false || itemData.approvedForComparison === true) {
          Vue.set(itemModel, 'approvedForComparison', itemData.approvedForComparison)
        }

        if (itemData.deleting === false || itemData.deleting === true) {
          Vue.set(itemModel, 'deleting', itemData.deleting)
        }
        
        if (itemData.hasNewValues === false || itemData.hasNewValues === true) {
          Vue.set(itemModel, 'hasNewValues', itemData.hasNewValues)
        }

        if (itemData.groups) {
          Vue.set(itemModel, 'groups', itemData.groups)
        }

        if (itemData.name) {
          Vue.set(itemModel, 'name', itemData.name)
        }
      }
      
    }
  },
  [types.MUTATION_REMOVE_TAXON_ITEM] (state, itemId) {
    if (state.currentTaxonItems) {
      Vue.delete(state.currentTaxonItems, itemId)
    }
  },
  [types.MUTATION_SET_TAXON_SELECTORS] (state, payload) {
    if (state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId]) {
      // console.log(`About to set the current apps selectors data to ${JSON.stringify(payload.selectors)}`)
      Vue.set(state.currentApp.taxons[payload.taxonId], 'selectors', payload.selectors)
    }
  },
  [types.MUTATION_SET_SERVICE_SETTINGS] (state, payload) {
    if (state.currentApp) {
      if (!state.currentApp.services) {
        // state.currentApp.services = {}
        Vue.set(state.currentApp, 'services', {})
      }
      if (!state.currentApp.services[payload.id]) {
        // state.currentApp.services[payload.id] = {}
        Vue.set(state.currentApp.services, `${payload.id}`, {})
      }
      Vue.set(state.currentApp.services, `${payload.id}`, payload.settings)
    }
  },
  [types.MUTATION_SET_APP_STORE_DETAILS] (state, payload) {
    if (state.currentApp) {

      Vue.set(state.currentApp, 'storeName', payload.storeName)
      Vue.set(state.currentApp, 'storeImg', payload.storeImg)
      
      if (payload.storeDescription) {
        Vue.set(state.currentApp, 'storeDescription', payload.storeDescription)
      }

      if (typeof payload.catalogFeedConfig !== 'undefined') {
        Vue.set(state.currentApp, 'catalogFeedConfig', payload.catalogFeedConfig)
      }
    }
  },
  [types.MUTATION_SET_APP_GPT_CONFIG] (state, payload) {
    if (state.currentApp) {
      if (payload) {
        Vue.set(state.currentApp, 'gptConfig', payload)
      } else {
        Vue.delete(state.currentApp, 'gptConfig')
      }
    }
  },
  [types.MUTATION_SET_APP_NAME] (state, name) {
    if (state.currentApp) {
      state.currentApp.name = name
    }
  },
  [types.MUTATION_SET_REFRESHING_TAXON] (state, newVal) {
    state.refreshingTaxon = newVal
  },
  [types.MUTATION_SET_LAST_TAXON_REFRESH_COUNT] (state, newVal) {
    state.lastTaxonRefreshCount = newVal
  },
  // Mutations to manage the current apps members map (id and roles)
  [types.MUTATION_SET_MEMBER] (state, memberData) {
    if (state.currentApp) {
      if (!state.currentApp.members) {
        Vue.set(state.currentApp, 'members', {})
      }
      Vue.set(state.currentApp.members, memberData.id, memberData)
    }
  },
  [types.MUTATION_UNSET_MEMBER] (state, memberId) {
    if (state.currentApp && state.currentApp.members && state.currentApp.members[memberId]) {
      Vue.delete(state.currentApp.members, `${memberId}`)
    }
  },
  // Mutations to manage members user data
  [types.MUTATION_CLEAR_MEMBERS] (state) {
    state.memberProfiles.splice(0)
  },
  [types.MUTATION_ADD_MEMBER] (state, memberData) {
    state.memberProfiles.push(memberData)
  },
  [types.MUTATION_UPDATE_MEMBER_ROLES] (state, memberData) {
    const member = state.memberProfiles.find(next => next.id === memberData.id)
    if (member) {
      Vue.set(member, 'roles', memberData.roles)
    }
  },
  [types.MUTATION_REMOVE_MEMBER] (state, memberId) {
    const memberIndex = state.memberProfiles.findIndex(next => next.id === memberId)
    if (memberIndex > -1) {
      state.memberProfiles.splice(memberIndex, 1)
    }
  },
  [types.MUTATION_SET_APP_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
      if (state.appUnSubscribeCallback) {
        state.appUnSubscribeCallback()
        state.appUnSubscribeCallback = null
      }
      state.appUnSubscribeCallback = newCallback
  },
  [types.MUTATION_SET_APP_TAXONS_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    if (state.appTaxonsUnSubscribeCallback) {
      state.appTaxonsUnSubscribeCallback()
      state.appTaxonsUnSubscribeCallback = null
    }
    state.appTaxonsUnSubscribeCallback = newCallback
  },
  [types.MUTATION_ADD_MEMBERS_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    // if (state.membersUnSubscribeCallback) {
      if (!state.membersUnSubscribeCallbacks) {
        state.membersUnSubscribeCallbacks = []
      }
      state.membersUnSubscribeCallbacks.push(newCallback)
  },
  async [types.MUTATION_CLEAR_MEMBERS_UNSUBSCRIBE_CALLBACKS] (state) {
    const clearableCopy = state.membersUnSubscribeCallbacks ? [...state.membersUnSubscribeCallbacks] : []
    state.membersUnSubscribeCallbacks = []

    for (let index = 0; index < clearableCopy.length; index++) {
      const nextCallback = clearableCopy[index];
      await nextCallback()
      
    }
  },
  [types.MUTATION_UPDATE_CATALOG_ITEMS_COUNT] (state, newCount) {
    if (state.currentApp) {

      Vue.set(state.currentApp, 'catalogItemsCount', newCount)
    }
  },
  [types.MUTATION_UPDATE_PAGINATED_ITEMS_COUNT] (state, newCount) {
    if (state.currentApp) {

      state.paginatedCatalogItemsCount = newCount
    }
  },
  [types.MUTATION_UPDATE_TAXON_ITEMS_COUNT] (state, newCount) {
    if (state.currentApp && state.currentTaxon) {

      Vue.set(state.currentTaxon, 'itemsCount', newCount)
    }
  },
  [types.MUTATION_SET_NAV_ITEM_EXPANDED] (state, itemRootRoute) {
    if (state.currentApp) {
      Vue.set(state.currentAppNavExpansions, itemRootRoute, true)
    }
  },
  // [types.MUTATION_ADD_TAXON_CRITERIA] (state, payload) {
  //   if (state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId]) {
  //     Vue.set(state.currentApp.taxons[payload.taxonId].criteria, payload.criteriaId, payload.criteriaData)
  //   }
  // },
  // [types.MUTATION_REMOVE_TAXON_CRITERIA] (state, payload) {
  //   if (state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId]) {
  //     Vue.delete(state.currentApp.taxons[payload.taxonId].criteria, payload.criteriaId)
  //   }
  // },
  // [types.MUTATION_TAXON_CRITERIA_SET_KEYWORD_ONLY] (state, payload) {
  //   if (state.currentApp && state.currentApp.taxons && state.currentApp.taxons[payload.taxonId]) {
  //     Vue.set(state.currentApp.taxons[payload.taxonId].criteria[payload.criteriaId], 'keywordOnly', payload.keywordOnly)
  //   }
  // }
  // [types.MUTATION_UPDATE_LAST_FETCHED_TIME] (state) {
  //   state.lastFetchedTime = Date.now()
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
